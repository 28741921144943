import axios from './axios'

const requests = {
	// 首页banner
	getIndeBanner(data = {}) {
		return axios('/paper/banner/list', {
			method: 'post',
			data: data
		})
	},
    // 渠道推荐位
    getRecommend(data = {}) {
        return axios('/paper/channelRecommend/list', {
            method: 'post',
            data: data
        })
    },
    // 推荐商品列表
    getRecommendBook(data = {}) {
        return axios('/paper/goods/recommendList', {
            method: 'post',
            data: data
        })
    },
	// 新书推荐
	getNewRecommend(data = {}) {
		return axios('/paper/goods/newRecommend', {
			method: 'post',
			data: data
		})
	},
	// 畅销图书
	getSellerRecommend(data = {}) {
		return axios('/paper/goods/sellerRecommend', {
			method: 'post',
			data: data
		})
	},
	// 文创产品
	getCreationRecommend(data = {}) {
		return axios('/paper/goods/creationRecommend', {
			method: 'post',
			data: data
		})
	},
	// 猜你喜欢
	getRandom(data = {}) {
		return axios('/paper/goods/random', {
			method: 'post',
			data: data
		})
	},
	// QA列表
	getCustomerQA(data = {}) {
		return axios('/paper/customerQA/list', {
			method: 'post',
			data: data
		})
	},
	// QA列表
	getSearchCon(data = {}) {
		return axios('/paper/goods/search', {
			method: 'post',
			data: data
		})
	},
	// 获取分类
	getList(data = {}) {
		return axios('/paper/category/list', {
			method: 'post',
			data: data
		})
	},
	// 获取首页2个vip图
	getVipList(data = {}) {
		return axios('/paper/vip/list', {
			method: 'post',
			data: data
		})
	},
	// 获取书籍详情
	getBookDet(data = {}) {
		return axios('/paper/goods/findOne', {
			method: 'post',
			data: data
		})
	},
	// 获取商品订单列表
	getOrderList(data = {}) {
		return axios('/paper/goodsOrder/list', {
			method: 'post',
			data: data
		})
	},
	// 密码登录
	passwordLogin(data = {}) {
		return axios('/paper/user/login', {
			method: 'post',
			data: data
		})
	},
	// 退出登录
	loginOut(data = {}) {
		return axios('/paper/user/loginOut', {
			method: 'post',
			data: data
		})
	},
	// 确认收货
	takeOver(data = {}) {
		return axios('/paper/goodsOrder/confirm', {
			method: 'post',
			data: data
		})
	},
	// 物流信息
	getLogistics(data = {}) {
		return axios('/paper/goodsOrderCourier/findOne', {
			method: 'post',
			data: data
		})
	},
	// 添加购物车
	addShopPingCar(data = {}) {
		return axios('/paper/shoppingCart/save', {
			method: 'post',
			data: data
		})
	},
	// 购物车列表
	getShopPingCar(data = {}) {
		return axios('/paper/shoppingCart/list', {
			method: 'post',
			data: data
		})
	},
	// 删除购物车
	removeGoods(data = {}) {
		return axios('/paper/shoppingCart/delete', {
			method: 'post',
			data: data
		})
	},
	//获取微信授权code
	getWeixinCode(data = {}) {
		return axios('/paper/user/getCode', {
			method: 'post',
			data: data
		})
	},
	//获取微信openid
	getWeixinOpenid(data = {}) {
		return axios('/paper/user/openid', {
			method: 'post',
			data: data
		})
	},
	//获取默认地址
	getAddress(data = {}) {
		return axios('/paper/userAddr/default', {
			method: 'get',
			data: data,
		})
	},
	//获取所有地址
	getAllAddress(data = {}) {
		return axios('/paper/userAddr/list', {
			method: 'get',
			data: data,
		})
	},
	//添加地址
	addAddress(data = {}) {
		return axios('/paper/userAddr/save', {
			method: 'post',
			data: data,
		})
	},
	//修改地址
	modifyAddress(data = {}) {
		return axios('/paper/userAddr/update', {
			method: 'post',
			data: data,
		})
	},
	//删除地址
	removeAddress(data = {}) {
		return axios('/paper/userAddr/delete', {
			method: 'post',
			data: data,
		})
	},
	//获取用户信息
	getUserMes(data = {}) {
		return axios('/paper/user/userInfo', {
			method: 'post',
			data: data,
		})
	},
	//更改密码
	changePassword(data = {}) {
		return axios('/paper/user/update', {
			method: 'post',
			data: data,
		})
	},
	//获取兑书卡数量
	getBookCardNum(data = {}) {
		return axios('/paper/userExchangeCount/count', {
			method: 'post',
			data: data,
		})
	},
	//下单
	takeOrder(data = {}) {
		return axios('/paper/goodsOrder/save', {
			method: 'post',
			data: data,
		})
	},
	//获取code
	getWxCode(data = {}) {
		return axios('/paper/user/getCode', {
			method: 'post',
			data: data,
		})
	},
	//获取openId
	getWxOpenId(data = {}) {
		return axios('/paper/user/openid', {
			method: 'post',
			data: data,
		})
	},
	//获取话费购信息传code
	getHfgMes(data = {}) {
		return axios('/paper/user/unicomCuauth', {
			method: 'post',
			data: data,
		})
	},
	//获取分类详情
	getClassifyDet(data = {}) {
		return axios('/paper/goods/category', {
			method: 'post',
			data: data,
		})
	},
	//获取订单详情
	getOrderDet(data = {}) {
		return axios('/paper/goodsOrder/findOne', {
			method: 'post',
			data: data,
		})
	},
	//获取电子书列表
	getEbookList(data = {}) {
		return axios('/paper/ebook/list', {
			method: 'post',
			data: data,
		})
	},
	//获取电子书列表（新)
	getEbookListX(data = {}) {
		return axios('/paper/books/getBookList', {
			method: 'post',
			data: data,
		})
	},
	//获取电子书详情
	getEbookDet(data = {}) {
		return axios('/paper/ebook/content', {
			method: 'post',
			data: data,
		})
	},
	
	//获取电子书详情(新)
	getBookDetail(data = {}) {
		return axios('/paper/books/getBookDetail', {
			method: 'post',
			data: data,
		})
	},
	//获取电子书章节
	getChapterList(data = {}) {
		return axios('/paper/books/getChapterList', {
			method: 'post',
			data: data,
		})
	},
	//获取小说详情
	getChapterdetail(data = {}) {
		return axios('/paper/books/getChapter', {
			method: 'post',
			data: data,
		})
	},
	//卡密兑换
	exChange(data = {}) {
		return axios('/paper/cdkey/exchange', {
			method: 'post',
			data: data,
		})
	},
	//banner专题
	getSpecial(data = {}) {
		return axios('/paper/specialRecord/list', {
			method: 'post',
			data: data,
		})
	},
	//获取vipUrl
	getVipUrl(data = {}) {
		return axios('/paper/user/gethfgoUrl', {
			method: 'post',
			data: data,
		})
	},
	//支付参数
	zhifuVipUrl(url,data = {}) {
		return axios(url, {
			method: 'post',
			data: data,
		})
	},
	//删除订单
	deleteOrder(data = {}) {
		return axios('/paper/goodsOrder/delete', {
			method: 'post',
			data: data,
		})
	},
	// 获取验证码
	getLoginCode(phone) {
		return axios('/common/sms/' + phone + '/1', {
			method: 'post',
			data: {},
		})
	},
	// 验证码登录
	codeLogin(data = {}) {
		return axios('/paper/user/woreadLogin', {
			method: 'post',
			data: data,
		})
	},
	// 前往登录页前获取loginUrl
	getChannelInfo(data = {}) {
		return axios('/common/channel/info', {
			method: 'post',
			data: data,
		})
	},
	// 获取兑书卡信息
	getBookCardMes(data = {}) {
		return axios('/paper/userExchangeCount/list', {
			method: 'post',
			data: data,
		})
	},
	telecomLogin(data = {}) {
		return axios('/paper/user/telecomLogin', {
			method: 'post',
			data: data,
		})
	},
	// 获取专区
	getSpecialArea(data = {}) {
		return axios('/paper/special/list', {
			method: 'post',
			data: data,
		})
	},
    wozhifuLogin(data = {}) {
        return axios('/paper/user/wozhifuUserInfo', {
            method: 'post',
            data: data,
        })
    },
	getCode(data = {}) {
        return axios('/paper/user/getCode', {
            method: 'post',
            data: data,
        })
    },
	userOpenid(data = {}) {
        return axios('/paper/user/openid', {
            method: 'post',
            data: data,
        })
    },
	// 获取签约验证码
	tonglianOrder(data = {}) {
        return axios('/paper/vipOrder/tonglianOrder', {
            method: 'post',
            data: data,
        })
    },
	// 提交签约验证码
	tonglianConfirm(data = {}) {
        return axios('/paper/vipOrder/tonglianConfirm', {
            method: 'post',
            data: data,
        })
    },
	// 签约领取列表
	userYuntingCountlist(data = {}) {
        return axios('/paper/userYuntingCount/list', {
            method: 'post',
            data: data,
        })
    },
	// 领取云听券码
	userYuntingCountOrder(data = {}) {
        return axios('/paper/userYuntingCount/order', {
            method: 'post',
            data: data,
        })
    },
	// 领取云听券码
	avoidlogin(data = {}) {
        return axios('/paper/user/avoidlogin?phone='+data.phone, {
            method: 'get',
        })
    },
	// // 示例
	// getNextPlay(data = {}){
	//   return axios('/fmChapter/playUrl',{
	//     method: 'post',
	//     data: data
	//   })
	// },



}
// 导出对象，然后在全局注册或在单个vue文件下调用
export default requests
