import Vue from "vue";
import VueRouter from "vue-router";
import home from "../views/home.vue";
import login from "../views/login.vue";
import passWord from "../views/passWord.vue";
import classify from "../views/classify.vue";
import vip from "../views/vip.vue";
import mine from "../views/mine.vue";
import car from "../views/shoppingCart.vue";
import bookDet from "../views/bookDet.vue";
import order from "../views/order.vue";
import addAddress from "../views/AddAddress.vue";
import address from "../views/Address.vue";
import mineVip from "../views/mineVip.vue";
import mineOrder from "../views/mineOrder.vue";
import search from "../views/search.vue";
import service from "../views/service.vue";
import logistics from "../views/logistics.vue";
import userMes from "../views/userMes.vue";
import eachClassify from "../views/eachClassify.vue";
import wxPay from "../views/wxPay.vue";
import ssoLogin from "../views/ssoLogin.vue";
import indexClassify from "../views/indexClassify.vue";
import orderDet from "../views/orderDet.vue";
import cdKey from "../views/cdKey.vue";
import vipEquity from "../views/vipEquity.vue";
import special from "../views/special.vue";
import success from "../views/success.vue";
import codeLogin from "../views/codeLogin.vue";
import bookCard from "../views/bookCard.vue";
import telecomSsoLogin from "../views/telecomSsoLogin.vue";
import wozhifuSsoLogin from "../views/wozhifuSsoLogin.vue";
import equity from "../views/Equity.vue";
import booklist from "../views/book/booklist.vue";
import bookdetail from "../views/book/bookdetail.vue";
import toRead from "../views/book/toRead.vue";
Vue.use(VueRouter);



const router = new VueRouter({
	mode: "history",
	base: "/page/",
	// mode: "hash",
	// base: "./",
	routes: [{
			path: "/",
			name: "home",
			redirect:"/home",
		},{
			path: "/home",
			name: "home",
			component: home
		},
		{
			path: "/login",
			name: "login",
			component: login
		},
		{
			path: "/passWord",
			name: "passWord",
			component: passWord
		},
		{
			path: "/classify",
			name: "classify",
			component: classify,
			meta: {
				keepAlive: true, // 此组件需要被缓存
			}
		},
		{
			path: "/vip",
			name: "vip",
			component: vip
		},
		{
			path: "/vipEquity",
			name: "vipEquity",
			component: vipEquity
		},
		{
			path: "/mine",
			name: "mine",
			component: mine
		},
		{
			path: "/car",
			name: "car",
			component: car
		},
		{
			path: "/bookDet",
			name: "bookDet",
			component: bookDet
		},
		{
			path: "/order",
			name: "order",
			component: order
		},
		{
			path: "/addAddress",
			name: "addAddress",
			component: addAddress
		},
		{
			path: "/address",
			name: "address",
			component: address
		},
		{
			path: "/mineVip",
			name: "mineVip",
			component: mineVip
		},
		{
			path: "/mineOrder",
			name: "mineOrder",
			component: mineOrder
		},
		{
			path: "/search",
			name: "search",
			component: search,
			meta: {
				keepAlive: true, // 此组件需要被缓存
			}
		},
		{
			path: "/service",
			name: "service",
			component: service
		},
		
		{
			path: "/logistics",
			name: "logistics",
			component: logistics
		},
		{
			path: "/userMes",
			name: "userMes",
			component: userMes
		},
		{
			path: "/eachClassify",
			name: "eachClassify",
			component: eachClassify,
			meta: {
				keepAlive: true, // 此组件需要被缓存
			}
		},
		{
			path: "/wxPay",
			name: "wxPay",
			component: wxPay
		},
		{
			path: "/ssoLogin",
			name: "ssoLogin",
			component: ssoLogin
		},
		{
			path: "/indexClassify",
			name: "indexClassify",
			component: indexClassify,
			meta: {
				keepAlive: true, // 此组件需要被缓存
			}
		},
		{
			path: "/orderDet",
			name: "orderDet",
			component: orderDet
		},
		{
			path: "/cdKey",
			name: "cdKey",
			component: cdKey
		},
		{
			path: "/special",
			name: "special",
			component: special
		},
		{
			path: "/success",
			name: "success",
			component: success
		},
		{
			path: "/codeLogin",
			name: "codeLogin",
			component: codeLogin
		},
		{
			path: "/bookCard",
			name: "bookCard",
			component: bookCard
		},
		{
			path: "/telecomSsoLogin",
			name: "telecomSsoLogin",
			component: telecomSsoLogin
		},
        {
            path: "/wozhifuSsoLogin",
            name: "wozhifuSsoLogin",
            component: wozhifuSsoLogin
        },
		{
            path: "/booklist",
            name: "booklist",
            component: booklist
        },
		{
            path: "/bookdetail",
            name: "bookdetail",
            component: bookdetail
        },
		{
            path: "/toRead",
            name: "toRead",
            component: toRead
        },
		{
			path: "/equity",
			name: "equity",
			component: equity
		},
		
	]

})
export default router;
