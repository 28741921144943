<template>
  <div style="background-color: #f84039; min-height: 100vh">
    <img src="../assets/vipEquityA.png" alt="" />
    <div class="content">
      <img class="content-lefticon" src="../assets/vipEquityB.png" alt="" />
      <div style="height: 40px"></div>
      <div class="content-list">
        <div v-for="(item, index) in lists" :key="index">
          <img
            v-if="item.status == 0"
            class="content-list-li"
            src="../assets/vipEquityC.png"
            alt=""
            @click="codeClick(item)"
          />
          <img v-else  class="content-list-li" @click="codeClick(item)" src="../assets/vipEquityD.png" alt="" />
        </div>

        <div style="height: 20px"></div>
      </div>
    </div>
    <div style="height: 40px"></div>
    <el-dialog :visible.sync="centerDialogVisible" width="80%" center>
      <div class="dialog-content">
        <div class="dialog-content-A">兑换码:{{ cditem.cdkeyNo}} <span @click="copyText(cditem.cdkeyNo)">复制</span></div>
        <div class="dialog-content-B">（有效期至：{{cditem.indate}}）</div>
        <div class="dialog-content-C">
          兑换路径:请前往“云听APP/小程序-我的-会员-兑换码兑换”进行兑换
        </div>
      </div>
    </el-dialog>
    <textarea v-model="text" style="display: none;"></textarea>
  </div>
</template>

<script>
import axios from "axios";
import Swiper from "swiper";
import "swiper/css/swiper.min.css";

export default {
  data() {
    return {
      centerDialogVisible: false,
      lists: [
        { title: "会员权益", img: require("../assets/vipEquityC.png") },
        { title: "会员权益", img: require("../assets/vipEquityC.png") },
        { title: "会员权益", img: require("../assets/vipEquityD.png") },
        { title: "会员权益", img: require("../assets/vipEquityD.png") },
      ],
      cditem:'',
      text:'',
    };
  },
  created() {
    this.viplist();
  },
  mounted() {},
  methods: {
    codeClick(item){
      if(item.status == 0){
        this.cditem = item;
        this.centerDialogVisible = true;
        return;
      }
      this.http.userYuntingCountOrder({id:item.id}).then((res) => {
        console.log(res, "444");
        if (res.data.status == 1) {
          this.cditem = res.data.data;
          this.centerDialogVisible = true;
          this.viplist()
        } else {
          this.$message({
            showClose: true,
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
    viplist() {
      this.http.userYuntingCountlist().then((res) => {
        console.log(res, "333");
        if (res.data.status == 1) {
          this.lists = res.data.data;
        } else {
          this.$message({
            showClose: true,
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
    copyText(text) {
      const textarea = document.createElement('textarea');
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
      alert('文案已复制！');
    }
  },
};
</script>

<style lang="scss" scoped>
image {
  width: 100%;
}
::v-deep .el-dialog {
  background: #f5e1c0 !important;
  border-radius: 20px;
}
::v-deep .el-dialog__body {
  padding: 0px 10px 30px !important;
}
.content {
  width: 90%;
  border-radius: 20px;
  background: #fff;
  margin: auto;
  position: relative;
  &-lefticon {
    position: absolute;
    left: 5px;
    top: 5px;
    width: 150px;
  }
  &-list {
    margin: auto;
    &-li {
      width: 45%;
      margin: 20px auto 0;
    }
  }
}
.dialog-content {
  text-align: center;
  background: #f5e1c0;
  padding: 20px 0;
  &-A {
    font-size: 18px;
    background: #f9eddc;
    color: #6a6867;
    display: inline-block;
    padding: 10px 5px;
    border-radius: 10px;
    span {
      color: #3593cb;
    }
  }
  &-B {
    font-size: 12px;
    color: #baad99;
    padding: 10px 0 20px;
  }
  &-C {
    font-size: 18px;
    color: #948e83;
    line-height: 30px;
  }
}
</style>
